import React from 'react';
import Modal from '@mui/material/Modal';

import { closeIcon } from 'Assets/Images';
import { GlossaryType } from 'Slices/book/types';
import ChapterList from 'Components/ChaptersList';
import styles from './styles.module.css';

interface ChaptersModalProps {
  open: boolean;
  glossary: GlossaryType[];
  handleClose: () => void;
}

export default function ChaptersModal({ open, handleClose, glossary }: ChaptersModalProps) {
  return (
    <Modal open={open} onClose={handleClose}>
      <div className={styles.container}>
        <div className={styles.header}>
          <span>Table of contents</span>
          <button className={styles.invisibleButton} onClick={handleClose}>
            <img src={closeIcon} alt="Icon" />
          </button>
        </div>
        <ChapterList glossary={glossary} handleClose={handleClose} />
      </div>
    </Modal>
  );
}
