import React from 'react';

import styles from './styles.module.css';

export interface TextInputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  rest?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
  disabled?: boolean;
  value?: string;
  innerRef?:
    | string
    | ((instance: HTMLInputElement | null) => void)
    | React.RefObject<HTMLInputElement>;
}

export default function TextInput({
  className,
  onChange,
  value,
  disabled,
  innerRef,
  ...rest
}: TextInputProps) {
  return (
    <input
      className={styles.input}
      value={value}
      onChange={onChange}
      disabled={disabled}
      type="text"
      ref={innerRef}
      {...rest}
    />
  );
}
