import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BookType, BookResponseType, GlossaryType } from './types';

interface StateType {
  loadingBooks: boolean;
  errorBooks: string;
  books: BookType[];
  glossary: GlossaryType[];
}

export const initialState: StateType = {
  loadingBooks: false,
  errorBooks: '',
  books: [],
  glossary: [],
};

export const bookSlice = createSlice({
  name: 'book',
  initialState,
  reducers: {
    getBooksSuccess: (state, action: PayloadAction<BookResponseType>) => {
      state.books = [...action.payload.book];
      state.glossary = [...action.payload.glossary];
      state.loadingBooks = false;
      state.errorBooks = '';
    },
    booksRequest: (state) => {
      state.loadingBooks = true;
      state.errorBooks = '';
    },
    booksError: (state, action: PayloadAction<string>) => {
      state.errorBooks = action.payload;
      state.loadingBooks = false;
    },
  },
});

export const { getBooksSuccess, booksError, booksRequest } = bookSlice.actions;

export default bookSlice.reducer;
