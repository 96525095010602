/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, TextArea, TextInput } from 'Components/Common';
import { getInitials } from 'Utils';
import { closeIcon } from 'Assets/Images';
import { createComment } from 'Slices/comment/apiCall';
import { COMMENT_TYPE, SelectionType } from 'Slices/comment/types';
import styles from './styles.module.css';

export default function CommentForm({
  selection,
  isReply,
  parentId,
  setSelection,
}: {
  selection: SelectionType | null;
  isReply?: boolean;
  parentId?: string;
  setSelection?: React.Dispatch<React.SetStateAction<SelectionType | null>>;
}) {
  const fullName = localStorage.getItem('name');
  const email = localStorage.getItem('email');
  const initials = getInitials(fullName);
  const dispatch = useDispatch();

  const [commentContent, setComment] = useState('');
  const [nameValue, setNameValue] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [isSent, setIsSent] = useState(false);

  const areFieldsInvalid = () =>
    (!fullName && !email && (nameValue.length === 0 || emailValue.length === 0)) ||
    !commentContent.length;

  const handleClick = () => {
    if (!localStorage.getItem('name')) {
      localStorage.setItem('name', nameValue);
      localStorage.setItem('email', emailValue);
    }
    if (selection) {
      dispatch(
        createComment({
          chapter: selection.chapterId,
          content: commentContent,
          email: email || emailValue,
          nickname: fullName || nameValue,
          referenceContent: selection.highlightContent,
          referenceId: selection.paragraphId,
          referencePos: selection.start,
          referenceLength:
            selection.end !== null && selection.start !== null
              ? selection.end - selection.start
              : null,
          type: COMMENT_TYPE.COMMENT,
        }),
      );
      setIsSent(true);
    } else {
      const comment = {
        chapter: '',
        content: commentContent,
        email: email || emailValue,
        nickname: fullName || nameValue,
        referenceContent: null,
        referenceId: null,
        referencePos: null,
        referenceLength: null,
        type: COMMENT_TYPE.COMMENT,
      };
      if (parentId) dispatch(createComment(comment, parentId));
      else dispatch(createComment(comment));
      setIsSent(true);
    }
  };

  const handleClose = () => {
    setComment('');
    if (selection && setSelection) setSelection(null);
  };

  useEffect(() => {
    if (isSent) {
      setComment('');
      if (selection) selection.highlightContent = '';
    }
  }, [isSent]);

  return (
    <div id="resizable" className={isReply ? styles.replyContianer : styles.contianer}>
      <div className={styles.firstContainer}>
        {!isReply && commentContent && (
          <button className={styles.invisibleButton} onClick={handleClose}>
            <img src={closeIcon} alt="Icon" />
          </button>
        )}
        <div className={styles.nameContainer}>
          {fullName ? (
            <>
              <div className={styles.initials}>{initials}</div>
              <span>{fullName}</span>
            </>
          ) : (
            <>
              <div className={styles.name}>
                <span>Name</span>
                <TextInput
                  value={nameValue}
                  onChange={(value) => setNameValue(value.target.value)}
                  placeholder="Your name"
                />
              </div>
              <div className={styles.email}>
                <span>Email</span>
                <TextInput
                  value={emailValue}
                  onChange={(value) => setEmailValue(value.target.value)}
                  placeholder="Your email"
                />
              </div>
            </>
          )}
        </div>
        {selection && (
          <h1 className={styles.highlightContainer}>
            <span className={styles.highlight}>{selection.highlightContent}</span>
          </h1>
        )}
        <TextArea
          value={commentContent}
          onChange={(value) => {
            setComment(value.target.value);
            setIsSent(false);
          }}
          placeholder="What are your thoughts?"
        />
      </div>
      <div className={styles.button}>
        <Button onClick={handleClick} isDisabled={areFieldsInvalid()}>
          {isReply ? 'Respond' : 'Comment'}
        </Button>
      </div>
    </div>
  );
}
