/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import Modal from '@mui/material/Modal';
import { useDispatch } from 'react-redux';

import CommentsList from 'Components/CommentsList';
import { CommentType, COMMENT_TYPE, SelectionType } from 'Slices/comment/types';
import { closeIcon, sendIcon } from 'Assets/Images';
import { TextArea, TextInput } from 'Components/Common';
import { createComment } from 'Slices/comment/apiCall';
import styles from './styles.module.css';

interface CommentsModalProps {
  open: boolean;
  comments: CommentType[];
  handleClose: () => void;
  amount: number;
  selection: SelectionType | null;
}

export default function CommentsModal({
  open,
  handleClose,
  comments,
  amount,
  selection,
}: CommentsModalProps) {
  const [commentValue, setComment] = useState('');
  const [nameValue, setName] = useState('');
  const [emailValue, setEmail] = useState('');
  const [parentId, setParentId] = useState<string | null>(null);
  const [isSent, setIsSent] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);
  const dispatch = useDispatch();
  // eslint-disable-next-line 
  const textInput = useRef<any>(null);
  const fullName = localStorage.getItem('name');
  const email = localStorage.getItem('email');

  const handleClick = () => {
    if (!localStorage.getItem('name')) {
      localStorage.setItem('name', nameValue);
      localStorage.setItem('email', emailValue);
    }
    if (selection) {
      dispatch(
        createComment({
          chapter: selection.chapterId,
          content: commentValue,
          email: email || emailValue,
          nickname: fullName || nameValue,
          referenceContent: selection.highlightContent,
          referenceId: selection.paragraphId,
          referencePos: selection.start,
          referenceLength:
            selection.end !== null && selection.start !== null
              ? selection.end - selection.start
              : null,
          type: COMMENT_TYPE.COMMENT,
        }),
      );
    } else {
      const comment = {
        chapter: '',
        content: commentValue,
        email: email || emailValue,
        nickname: fullName || nameValue,
        referenceContent: null,
        referenceId: null,
        referencePos: null,
        referenceLength: null,
        type: COMMENT_TYPE.COMMENT,
      };
      if (parentId) dispatch(createComment(comment, parentId));
      else dispatch(createComment(comment));
    }
    setIsSent(true);
    handleClose();
    setInputFocused(false);
  };

  useEffect(() => {
    if (isSent) {
      setComment('');
      if (selection) selection.highlightContent = '';
    }
  }, [isSent]);

  const handleAddReplyMobile = (id) => {
    setParentId(id);
    textInput.current.focus();
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
        setInputFocused(false);
        setComment('');
        if (selection) selection.highlightContent = '';
      }}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <span>Recent Comments ({amount})</span>
          {commentValue && (
            <button
              className={styles.invisibleButton}
              onClick={() => {
                handleClose();
                setInputFocused(false);
                setComment('');
                if (selection) selection.highlightContent = '';
              }}
            >
              <img src={closeIcon} alt="Icon" />
            </button>
          )}
        </div>
        <CommentsList
          comments={comments}
          isInputFocused={inputFocused}
          handleAddReplyMobile={handleAddReplyMobile}
        />
        <div className={styles.footer}>
          {!fullName && (
            <div className={styles.nameContainer}>
              <div className={styles.name}>
                <span>Name</span>
                <TextInput
                  value={nameValue}
                  onChange={(value) => setName(value.target.value)}
                  placeholder="Your name"
                />
              </div>
              <div className={styles.email}>
                <span>Email</span>
                <TextInput
                  value={emailValue}
                  onChange={(value) => setEmail(value.target.value)}
                  placeholder="Your email"
                />
              </div>
            </div>
          )}
          {selection && (
            <h1 className={styles.highlightContainer}>
              <span className={styles.highlight}>{selection.highlightContent}</span>
            </h1>
          )}
          <div className={styles.row}>
            <TextArea
              innerRef={textInput}
              value={commentValue}
              onFocus={() => setInputFocused(true)}
              onChange={(value) => setComment(value.target.value)}
              placeholder="What are your thoughts?"
            />
            <button
              className={styles.invisibleButton}
              onClick={() => {
                handleClick();
                setInputFocused(false);
              }}
            >
              <img src={sendIcon} alt="send icon" />
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
