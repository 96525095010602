import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { GlossaryType } from 'Slices/book/types';
import { cleanParagraph } from 'Utils';
import styles from './styles.module.css';

export interface ChapterListProps {
  glossary: GlossaryType[];
  handleClose?: () => void;
}
export default function ChapterList({ glossary, handleClose }: ChapterListProps) {
  const location = useLocation();

  return (
    <div className={styles.container}>
      {glossary.map(({ ref, title }) => (
        <NavLink
          key={ref}
          onClick={handleClose}
          exact
          className={location.pathname === `/${ref}` ? styles.currentLink : styles.link}
          to={`#${ref}`}
          activeClassName={styles.activeNavlink}
        >
          {cleanParagraph(title)}
        </NavLink>
      ))}
    </div>
  );
}
