import React, { useState } from 'react';

import { CommentType } from 'Slices/comment/types';
import { calculateDays, camelize, getInitials } from 'Utils';
import { chatIcon } from 'Assets/Images';
import CommentForm from 'Components/CommentForm';
import CommentsList from 'Components/CommentsList';
import { useIsMobile } from 'Hooks';
import styles from './styles.module.css';

export interface CommentProps {
  comment: CommentType;
  isLast?: boolean;
  isReply?: boolean;
  handleAddReplyMobile?: (parentId: string) => void;
}
export default function Comments({ comment, isLast, isReply, handleAddReplyMobile }: CommentProps) {
  const { byNickname, content, replies, createdAt, referenceContent, id } = comment;
  const initials = getInitials(byNickname);
  const time = calculateDays(createdAt);
  const isMobile = useIsMobile();
  const [isAddReply, setIsAddReply] = useState(false);
  const [showReplies, setShowReplies] = useState(false);

  const handleClick = () => {
    if (handleAddReplyMobile) handleAddReplyMobile(id);
    return !showReplies && setIsAddReply(!isAddReply);
  };

  const getClassname = () => {
    if (isLast && referenceContent) return styles.lastHighlightCommentCard;
    if (isLast) return styles.lastCommentCard;
    if (referenceContent) return styles.highlightCommentCard;
    return styles.commentCard;
  };

  return (
    <div className={getClassname()}>
      <div className={styles.row}>
        <div className={styles.initials}>{initials}</div>
        <div className={styles.column}>
          <span className={styles.name}>{byNickname}</span>
          <span className={styles.time}>{time}</span>
        </div>
      </div>
      <div className={styles.contentContainer}>
        {referenceContent && (
          <h1 className={styles.highlightContainer}>
            <span className={styles.highlight}>{referenceContent}</span>
          </h1>
        )}
        <span className={styles.content}>{content}</span>
      </div>
      {!isReply && (
        <div className={styles.bottom}>
          <button
            className={styles.invisibleButton}
            onClick={() => !isAddReply && replies.commentCount > 0 && setShowReplies(!showReplies)}
          >
            <img className={styles.icon} src={chatIcon} alt="chat icon" />
            {showReplies ? (
              <span>Hide comments</span>
            ) : (
              replies.commentCount > 0 && <span>{replies.commentCount}</span>
            )}
          </button>
          <button className={styles.invisibleButton} onClick={handleClick}>
            Add Reply
          </button>
        </div>
      )}
      {isAddReply && !isMobile && <CommentForm selection={null} isReply parentId={id} />}
      {showReplies && (
        <div className={styles.list}>
          <CommentsList comments={camelize(replies.data)} isReply />
        </div>
      )}
    </div>
  );
}
