/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { fromRange } from 'xpath-range';
import Highlighter from 'react-highlight-words';
import { useDispatch } from 'react-redux';

import { BookType } from 'Slices/book/types';
import { createComment, deleteComment } from 'Slices/comment/apiCall';
import {
  appLogo,
  highlighterIcon,
  chatWhiteIcon,
  chatIcon,
  bookmarkIcon,
  bookmarkFilledIcon,
  highlighterFilledIcon,
  LinkedinIcon,
} from 'Assets/Images';
import { COMMENT_TYPE, SelectionType } from 'Slices/comment/types';
import { useIsMobile } from 'Hooks';
import { cleanParagraph } from 'Utils';
import styles from './styles.module.css';

interface BookProps {
  book: BookType;
  comments: {
    id: string;
    referenceId: string | null;
    highlightContent: string | null;
    start: number | null;
    end: number | null;
    type: 'HIGHLIGHT' | 'COMMENT';
  }[];
  handleClick: (id: string) => void;
  setSelection: React.Dispatch<React.SetStateAction<SelectionType | null>>;
}

export default function BookComponent({
  book: bookProp,
  comments,
  handleClick,
  setSelection,
}: BookProps) {
  const book = bookProp?.book;
  const { hash, pathname } = useLocation();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const fullName = localStorage.getItem('name');
  const email = localStorage.getItem('email');
  // eslint-disable-next-line 
  const paragraphRef = useRef<any>(null);
  // eslint-disable-next-line 
  const iconsRef = useRef<any>(null);
  const [highlight, setHighlight] = useState<SelectionType | null>(null);
  const [scrollY, setScrollY] = useState(0);
  const [filledBookmark, setBookmark] = useState(false);
  const [selectedComment, setSelectedComment] = useState('');
  const [position, setPosition] = useState<{
    bottom: number;
    height: number;
    left: number;
    right: number;
    top: number;
    width: number;
    x: number;
    y: number;
  } | null>(null);

  const hasComments = (id: string) =>
    comments.find(({ referenceId, type }) => referenceId === id && type === COMMENT_TYPE.COMMENT);

  const getAmountComments = (id: string) =>
    comments.reduce((result, item) => {
      if (item.referenceId === id && item.type === COMMENT_TYPE.COMMENT) result += 1;
      return result;
    }, 0);

  const findChunks = ({ searchWords }) => {
    const commentsWithHighlights = comments.filter(
      ({ highlightContent }) => searchWords.includes(highlightContent) && highlightContent,
    );
    return commentsWithHighlights.map(({ start, end }) => ({ start, end }));
  };

  const closePopper = (e) => {
    if (
      paragraphRef.current &&
      position &&
      !paragraphRef.current.contains(e.target) &&
      !iconsRef?.current?.contains(e.target)
    ) {
      setPosition(null);
      setSelectedComment('');
    }
  };

  document.addEventListener('mousedown', closePopper);

  useEffect(() => {
    const bookmark = localStorage.getItem('mark');
    if (bookmark) {
      setTimeout(() => {
        const element = document.getElementById(bookmark);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [book]);

  useEffect(() => {
    if (hash === '') {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash]);

  const handleMouseUp = (id: string, chapter: string) => {
    const getSelection = window.getSelection();
    if (getSelection && getSelection.rangeCount) {
      const rect = getSelection.getRangeAt(0).getBoundingClientRect();
      setPosition(rect);
      setScrollY(window.scrollY);
      const { startOffset, endOffset } = fromRange(getSelection.getRangeAt(0));
      setHighlight({
        start: startOffset,
        end: endOffset,
        paragraphId: id,
        chapterId: chapter,
        highlightContent: getSelection.toString(),
      });
      setBookmark(false);
    }
  };

  const handleComment = () => {
    setPosition(null);
    setSelection(highlight);
  };

  const handleHighlight = () => {
    setPosition(null);
    if (highlight) {
      dispatch(
        createComment({
          chapter: highlight.chapterId,
          content: '',
          email: email || '',
          nickname: fullName || '',
          referenceContent: highlight.highlightContent,
          referenceId: highlight.paragraphId,
          referencePos: highlight.start,
          referenceLength:
            highlight.end !== null && highlight.start !== null
              ? highlight.end - highlight.start
              : null,
          type: COMMENT_TYPE.HIGHLIGHT,
        }),
      );
    }
  };

  const handleUnhighlight = () => {
    dispatch(deleteComment({ id: selectedComment }));
    setPosition(null);
    setSelectedComment('');
  };

  const handleHighlightClick = (id: string) => {
    const getSelection = window.getSelection();
    const { startOffset, endOffset } = fromRange(getSelection?.getRangeAt(0));
    const clickedHighlights = comments.find(
      ({ referenceId, type, start, end }) =>
        referenceId === id &&
        type === COMMENT_TYPE.HIGHLIGHT &&
        start &&
        end &&
        start <= startOffset &&
        end >= endOffset,
    );
    if (getSelection && clickedHighlights) {
      setSelectedComment(clickedHighlights.id);
    }
  };

  const showPopup = () => {
    const oauthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_LINKEDIN_REDIRECT_URI}&state=${process.env.REACT_APP_LINKEDIN_STATE}&scope=w_member_social%20r_liteprofile`;
    const width = 600;
    const height = 630;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    localStorage.setItem('highlightContent', highlight?.highlightContent || '');
    window.open(
      oauthUrl,
      'Linkedin',
      `menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=${width}, height=${height}, top=${top}, left=${left}`,
    );
  };

  const handleBookmark = () => {
    if (highlight) {
      localStorage.setItem('mark', highlight.paragraphId);
      setBookmark(true);
    }
  };

  return (
    <div className={styles.bookContianer}>
      <img src={appLogo} className={isMobile ? styles.appLogoMobile : styles.appLogo} alt="Build for Better" />
      <div className={styles.title}>{book?.title}</div>
      <div className={styles.subtitle}>
        <span>{book?.subtitle}</span>
        <span> by {book?.author}</span>
      </div>
      {book?.chapters.map((elem) => (
        <div id={elem.ref} key={elem.ref} className={styles.chapter}>
          <div className={styles.chapterTitle}>{cleanParagraph(elem.chapter)}</div>
          <div className={styles.chapterSubtitle}>{elem.subtitle}</div>
          <div className={styles.text} ref={paragraphRef}>
            {elem.content.map(({ id, content, type: pType, image, subchapter }) => (
              <div key={id} id={id}>
                <div className={styles.subchapter}>{subchapter}</div>
                <div
                  className={styles.paragraph}
                  onMouseUp={() => handleMouseUp(id, elem.ref)}
                  onTouchEndCapture={() => handleMouseUp(id, elem.ref)}
                  onDoubleClick={(event) => event.preventDefault()}
                  onClick={() => {
                    handleHighlightClick(id);
                  }}
                >
                  {(pType === 'ul_li' || pType === 'nested_ul_li') && (
                    <ul>
                      <li className={pType === 'nested_ul_li' ? styles.inner : ''}>
                        <Highlighter
                          highlightClassName={styles.highlight}
                          className={styles.text}
                          searchWords={comments
                            .filter(
                              ({ referenceId, type }) =>
                                referenceId === id && type === COMMENT_TYPE.HIGHLIGHT,
                            )
                            .map(({ highlightContent }) => highlightContent)}
                          findChunks={findChunks}
                          textToHighlight={cleanParagraph(content)}
                        />
                        <div className={styles.hidden}>{cleanParagraph(content)}</div>
                      </li>
                    </ul>
                  )}
                  {pType === 'subheading' && (
                    <div className={styles.subchapter}>{cleanParagraph(content)}</div>
                  )}
                  {pType === 'p' && (
                    <>
                      <Highlighter
                        highlightClassName={styles.highlight}
                        className={styles.text}
                        searchWords={comments
                          .filter(
                            ({ referenceId, type }) =>
                              referenceId === id && type === COMMENT_TYPE.HIGHLIGHT,
                          )
                          .map(({ highlightContent }) => highlightContent)}
                        findChunks={findChunks}
                        textToHighlight={cleanParagraph(content)}
                      />
                      <div className={styles.hidden}>{cleanParagraph(content)}</div>
                    </>
                  )}
                  <div className={styles.center}>
                    {image && <img src={image} alt={`${elem.chapter}`} className={styles.img} />}
                  </div>
                  {hasComments(id) && (
                    <button className={styles.comment} onClick={() => handleClick(id)}>
                      <img src={chatIcon} alt="chat icon" />
                      <span className={styles.number}>{getAmountComments(id)}</span>
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
      {position &&
        (position.width >= 1 || selectedComment) &&
        position.x < paragraphRef.current?.offsetWidth && (
          <div
            style={{
              left: isMobile
                ? position.left + position.width / 4
                : position.left + position.width / 3,
              top: isMobile ? position.y + position.height + 5 + scrollY : position.y - 55,
              position: 'absolute',
              background: '#262626',
              borderRadius: 8,
              display: 'flex',
            }}
            ref={iconsRef}
            className={styles.popper}
          >
            {selectedComment ? (
              <button className={styles.invisibleButton} onClick={handleUnhighlight}>
                <img
                  src={highlighterFilledIcon}
                  className={isMobile ? styles.bigIconHighlight : ''}
                  alt="highlight"
                />
              </button>
            ) : (
              <button className={styles.invisibleButton} onClick={handleHighlight}>
                <img
                  src={highlighterIcon}
                  className={isMobile ? styles.bigIconHighlight : ''}
                  alt="highlight"
                />
              </button>
            )}
            <button className={styles.invisibleButton} onClick={handleComment}>
              <img src={chatWhiteIcon} className={isMobile ? styles.bigIcon : ''} alt="highlight" />
            </button>
            <button className={styles.invisibleButton} onClick={showPopup}>
              <img
                src={LinkedinIcon}
                width={24}
                className={isMobile ? styles.bigIcon : ''}
                alt="linkedin"
              />
            </button>
            <button className={styles.invisibleButton} onClick={handleBookmark}>
              <img
                src={filledBookmark ? bookmarkFilledIcon : bookmarkIcon}
                className={isMobile ? styles.bigIcon : ''}
                alt="highlight"
              />
            </button>
          </div>
        )}
    </div>
  );
}
