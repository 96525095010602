/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import { MainLayout } from 'Layouts';
import { REACT_APP_CONTACT_EMAIL } from 'Utils/constants';
import styles from './styles.module.css';

export default function PrivacyPolicy() {
  const email = REACT_APP_CONTACT_EMAIL;
  return (
    <div className={styles.screen}>
      <MainLayout mainClassname={styles.container}>
        <div className={styles.sapcedRow}>
          <div className={styles.title}>PRIVACY NOTICE</div>
          <div>
            <div className={styles.smallText}>Version Date: September 12, 2022</div>
            <div className={styles.smallText}>Rev. 13461BC</div>
            <div className={styles.smallText}>State of California</div>
          </div>
        </div>
        <div className={styles.text}>
          <div className={styles.sectionTitle}>1. GENERAL</div>

          <p>
            Authored Appand PracticalProductPhilosophy.com ( or "we" or "us" or "our") respects the
            privacy of everyone ("Sites" visitor" "user" or "you") that uses our websites at
            Authored.app and PracticalProductPhilosophy.com, as well as other device or online
            applications related or connected thereto (collectively, the "Sites").
          </p>
          <p>
            The following Authored App and PracticalProductPhilosophy.com Privacy Notice ("Privac y
            Notice") is designed to inform you, as a user of the Sites, about the types of personal
            information that Authored App and PracticalProductPhilosophy.com may gather about or
            collect from you in connection with your use of the Sites. It also is in tended to
            explain the conditions under which Authored App and PracticalProductPhilosophy.com uses
            and discloses that personal information, and your rights in relation to that personal
            information.
          </p>
          <p>
            The Sites are hosted in the United States and is subject to U.S. state and federal law.
            If you are accessing our Sites from other jurisdictions, please be advised that you are
            transferring your personal information to us in the United States, and by using our
            Sites, you are agreeing to that transfer and use of yo ur personal information i n
            accordance with this Privacy Notice. You also agree to abide to the applicable laws of
            the State of California and U.S. federal law concerning your use of the Sites and your
            agreements with us. If your use of the Sites would be u nlawful in your jurisdict ion,
            please do not use the Sites.
          </p>
          <div className={styles.sectionSubtitle}>Company Information</div>
          <div>Authored App and PracticalProductPhilosophy.com</div>
          <div>8149 Santa Monica Blvd</div>
          <div>West Hollywood, CA 90046</div>
          <div>
            Email:
            <button
              className={styles.link}
              // eslint-disable-next-line no-return-assign
              onClick={() => (window.location.href = `mailto:${email}`)}
            >
              {email}
            </button>
          </div>
          <div className={styles.sectionTitle}>
            2. HOW WE COLLECT AND USE YOUR PERSON AL INFORMATION
          </div>
          <p>
            Authored App and PracticalProductPhilosophy.com gathers personal information from users
            of the Sites. When you browse our Sites, subscribe to our services or contact us through
            various social or web forms you are voluntarily sharing persona l information with us.
            This personal inform ation also includes various data that we collect automatically.
            This may be the user’s Internet Protocol (IP) address, operating system, browser type
            and the locations of the Sites the user views right before arri ving at, while
            navigating and immediately a fter leaving the Sites. It may also include various
            technical aspects of user’s computer or browser and users browsing habits that are
            collected through cookies. Authored App and PracticalProductPhilosophy.com may analyze
            various mentioned personal information gat hered from or about users to help Authored
            App and PracticalProductPhilosophy.com better understand how the Sites are used and how
            to make them better. By identifying patterns and trends in usage, Authored App and
            PracticalProductPhilosophy.com is able to better design th e Sites to improve users’
            experiences, both in terms of content and ease of use. From time to time, Authored App
            and PracticalProductPhilosophy.com may also release the anonymized informati on gathered
            from the users in the aggregate, such as by publishing a report on trends in the usage
            of the Sites.
          </p>
          <p>
            When we believe disclosure is appropriate, we may disclose your information to help
            investigate, prevent or take other action regarding illega l activity, suspected fraud
            or other wrongdoing; to protect and defend the rights, property or safety of Authored
            App and PracticalProductPhilo sophy.com , our users, our employees or others; to comply
            with applicable law or cooperate with law enforcement; t o enforce our Terms of Use or
            other agreements or policies, in response to a subpoe na or similar investigative
            demand, a court order or a request for cooperation from a law enforcement or other
            government agency; to establish or exercise our legal rights; to defend against legal
            claims; or as otherwise required by law. In such cases, we may raise or waive any legal
            objection or right available to us. We will notify you if permitted before undertaking
            such disclosures.
          </p>
          <p>
            Authored App and PracticalProductPhilo sophy.com reserves the right to transfer all
            information in its possession to a successor o rganization in the event of a merger,
            acquisition, bankruptcy or other sale of all or a portion of Authored App and
            PracticalProductPhilosophy.com ’s assets. Other th an to the extent ordered by a
            bankruptcy or other court, the use and disclosure of all transferred information will be
            subject to this Privacy Notice, or to a new privacy notice if you are given notice of
            that new privacy notice and are given an opportunit y to affirmatively opt - out of it.
          </p>
          <div className={styles.sectionTitle}>3. DO WE SHARE YOUR PERSONAL INFORMATION?</div>
          <div className={styles.italic}>General Provisions</div>
          <p>
            Authored App and PracticalProductPhilosophy.com contractually prohibits its contractors,
            affiliates, vendors and suppliers from disclosing any personal informat ion received
            from Authored App and PracticalProductPhilosophy.com, other than in accordance with this
            Privacy Notice. These third parties may include advertisers, providers of games,
            utilities, widgets and a variety of other third - party applications accessible through
            the Sites. Authored App and PracticalProductPhilosophy.com neither owns nor controls the
            third - party websites and applications accessible through the Sites. Thus, this Privacy
            Notice does not apply to personal information provided to or gathered by the third
            parties that operate them. Before visiting a third party, or using a third - party
            application, whether by me ans of a link on the Sites, directly through the Sites or
            otherwise, and before providing any personal information to any such third party, users
            should inform themselves of the privacy policies and practices (if any) of the third
            party responsible for tho se Sites or applications, and should take those steps
            necessary to, in those users’ discretion, protect their privacy.
          </p>
          <p>
            We may use third - party vendors, including Google, who use first - party cookies (such
            as the Google Analytics cookie) and third - party cookies (such as the DoubleClick
            cookie) together to inform, optimize and serve ads based on your past activity on the
            Sites, including Google Analytics for Display Advertising. The personal information
            collected may be used to, among other things, analyze and track data, determine the
            popularity of certain content and better understand online activity. If you do not want
            any pe rsonal information to be collected and used by Google Analytics, you can install
            an opt - out in your web browser (
            <a href="https://tools.google.com/dlpage/gaoptout/">
              https://tools.google.com/dlpage/gaoptout/
            </a>
            ) and/or opt out from Google Analytics for Display Advertising or the Google Display
            Network by using Google’s Ads Settings (
            <a href="www.google.com/settings/ads">www.google.com/settings/ads</a>).
          </p>
          <div className={styles.italic}>Social Media</div>
          <p>
            We may use hyperlinks on the Sites which will redirect you to a social network if you
            click on the respective link. However, when you click on a social plug - in, such as
            Facebook’s "Like" button or Twitter’s “tweet” button that particular social network’s
            plugin will be activated and your browser will directly connect to that provider’s
            servers. If you do not use these buttons none of your data will be sent to the
            respective social network’s plugin prov iders. To illustrate this further, imagine the
            scenario where you click on the Facebook’s "Like" button on the Sites. Facebook will
            receive your IP address, the browser version and screen resolution, and the operating
            system of the device you have used to access the Sites. Settings regarding privacy
            protection can be found on the websites of these social networks and are not within our
            control.
          </p>
          <div className={styles.italic}>Third - Party Service Providers</div>
          <p>
            We may share your personal information, which may include your name and contact in
            formation (including email address) with our authorized service providers that perform
            certain services on our behalf. These services may include fulfilling orders, providing
            customer service and marketing assistance, performing business and sales analysis ,
            supporting the Sites’ functionality and supporting contests, sweepstakes, surveys and
            other features offered through the Sites. We may also share your name, contact personal
            information and credit card personal information with our authorized service providers
            who process credit card payments. These service providers may have access to personal
            information needed to perform their functions but are not permitted to share or use such
            personal information for any other purpose.
          </p>
          <p>
            If you have opted in to receiving marketing communication from us then Authored App and
            PracticalProductPhilosophy.com may provide your personal information to third parties,
            with your consent, so that those third parties may directly contact you about additional
            products and services. To cease having your personal information used for marketing
            communication purposes, you can:
          </p>
          <ul>
            <li>Opt out by clicking on the unsubscribe button found in the footer of our emails</li>
          </ul>
          <div className={styles.sectionTitle}>4. SECURITY</div>
          <p>
            We take the security of your personal information seriously and use reasonable
            electronic, personnel and physical measures to protect it from loss, theft, alteration
            or misuse. However, please be advised that even the best security measures cannot fully
            eliminate all risks. We cannot guarantee that only authorized persons will view your
            personal information. We are not responsible for third - party circumvention of any
            privacy settings or security measures.
          </p>
          <p>
            We are dedicated to protect all personal information on the Sites as is necessary.
            However, you are responsible for maintaining the confidentiality of your personal
            information by keeping your password and computer/mobile device access confidential. If
            you have an account with Authored App and PracticalProductPhilosophy.com and believe
            someone has gained unauthorized access to it or your account please change your password
            as soon as possible. If you lose control of your account, you should notify us
            immediately.
          </p>
          <div className={styles.sectionTitle}>5. PRIVACY RIGHTS - EU USERS</div>

          <p>
            If you reside in the European Union (EU), United Kingdom (UK), Switzerland, Norway,
            Lichtenstein, or Iceland, you have certain data protection rights under your local laws.
          </p>
          <p>These rights may include:</p>
          <ul>
            <li>The right to request access and obtain a copy of your personal information</li>
            <li>The right to request edits or erasure of your personal information</li>
            <li>
              The right to limit the processing of your personal information Data portability right
              (if applicable)
            </li>
            <li>The right to object to the processing of your personal information</li>
          </ul>
          <p>
            If Authored App and PracticalProductPhilosophy.com relies on your consent to process
            your personal information, you have the right to withdraw your consent at any time.
            However, please note that this will not alter the validity of the processing before its
            withdrawal.
          </p>
          <p>
            If you wish to exercise any of the mentioned rights please contact us by email at
            <button
              className={styles.link}
              // eslint-disable-next-line no-return-assign
              onClick={() => (window.location.href = `mailto:${email}`)}
            >
              {email}
            </button>
            , or by referring to the contact details at the bottom of this Privacy Notice. Authored
            App and PracticalProductPhilosophy.com has 30 days to respond to your request.
          </p>
          <p>
            If you are not satisfied with Authored App and PracticalProductPhilosophy.com's handling
            of your privacy concerns please note that you have the right to complain to your local
            data protection supervisory authority.
          </p>
          <p>
            List of EU supervisory authorities:
            <a href="http://ec.europa.eu/justice/data">http://ec.europa.eu/justice/data</a> -
            protection/bodies/authoriti es/index_en.htm.
          </p>
          <p>
            Switzerland supervisory authority:
            <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">
              https://www.edoeb.admin.ch/edoeb/en/home.html
            </a>
            .
          </p>
          <p>
            If you have any questions or comments about your privacy rights, you may email us at
            <button
              className={styles.link}
              // eslint-disable-next-line no-return-assign
              onClick={() => (window.location.href = `mailto:${email}`)}
            >
              {email}
            </button>
            .
          </p>
          <div className={styles.sectionTitle}>6. PRIVACY RIGHTS - CALIFORNIA USERS</div>
          <div className={styles.italic}>
            Do Not Sell My Personal Information Notice - California Consumer Privacy Act (CCPA)
          </div>
          <p>
            Authored App and PracticalProductPhilosophy.com has not disclosed or sold any personal
            information to third parties for a business or commercial purpose in the preceding
            twelve (12) months. Authored App and PracticalProductPhilosophy.com will not sell
            personal information in the future belonging to Sites' visitors, users and other
            consumers.
          </p>
          <div className={styles.italic}>If you are under 18 years of age</div>
          <p>
            If you have registered account with Authored App and PracticalProductPhilosophy.com, you
            have the right to request the removal of unwanted personal information that you publicly
            post on our Sites. To request the removal of such information, please contact us using
            the contact info rmation provided below. Make sure to include your account's email
            address and a statement that you reside in California.
          </p>
          <div className={styles.italic}>"Shine the Light Law"</div>
          <p>
            California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits
            our users who are California residents to request and obtain from us, once a year and
            free of charge, personal information about the personal information (if any) we
            disclosed to third parties for direct marketing purposes in the prece ding calendar
            year. If applicable, this personal information would include a list of the categories of
            the personal information that was shared and the names and addresses of all third
            parties with which we shared personal information in the immediately preceding calendar
            year. If you are a California resident and would like to make such a request, please
            submit your request in writing to the address listed below.
          </p>
          <div className={styles.sectionTitle}>7. CHILDREN</div>
          <p>
            The Children's Online Privacy Protection Act ("COPPA") protects the online privacy of
            children under 13 years of age. We do not knowingly collect or maintain personal
            information from anyone under the age of 13, unless or except as permitted by law. Any
            person who provides personal information through the Sites represents to us that he or
            she is 13 years of age or older. If we learn that personal information has been
            collected from a user under 13 years of age on or through the Sites, then we will take
            the appropriate steps to cause this personal information to be deleted. If you are the
            parent or legal guardian of a child under 13 who has become a member of the Sites or has
            otherwise transferred personal information to the Sites, please contact Authored App and
            PracticalProductPhilosophy.com using our contact personal information below to have that
            child's account terminated and personal information deleted.
          </p>
          <div className={styles.sectionTitle}>8. DO - NOT - TRACK NOTICE</div>
          <p>
            Most web browsers and some mobile operating systems include a Do - Not - Track ("DNT")
            feature or setting you can activate to signal your privacy preference not to have data
            about your online browsing activities monitored and collected. Because there is not yet
            a common understanding of how to interpret the DNT signal, the Sites currently do not
            respond to DNT browser signals or mechanisms.
          </p>
          <div className={styles.sectionTitle}>9. GOVERNING LAW</div>
          <p>
            Disputes over privacy issues contained in this Privacy Notice will be governed by the
            law of the State of California. You also agree to abide by any limitation on damages
            contained in our Terms of Use, or other agreement that we have with you.
          </p>
          <div className={styles.sectionTitle}>10. PRIVACY N OTICE CHANGES</div>
          <p>
            Authored App and PracticalProductPhilosophy.com may, in its sole discretion, change this
            Privacy Notice from time to time. Any and all changes to Authored App and
            PracticalProductPhilosophy.com’s Privacy Notice will be reflected on this page and the
            date of the new version will be stated at the top of this Privacy Notice. Unless stated
            otherwise, our current Privacy Notice applies to all personal information that we have
            about you and your account. Users should regularly check this page for any changes to
            this Privacy Notice. Authored App and PracticalProductPhilosophy.com will always post
            new versions of the Privacy Notice on the Sites. However, Authored App and
            PracticalProductPhilosophy.com may, as determined in its discretion, decide to notify
            users of changes made to this Privacy Notice via email or otherwise. Accordingly, it is
            important that users always maintain and update their contact personal information.
          </p>
          <div className={styles.sectionTitle}>11. CONTACT</div>
          <p>If you have any questions regarding our Privacy Notice, please co ntact us at:</p>
          <div>Authored App and PracticalProductPhilosophy.com</div>
          <div>8149 Santa Monica Blvd</div>
          <div>West Hollywood, CA 90046</div>
          <div>
            Email:
            <button
              className={styles.link}
              // eslint-disable-next-line no-return-assign
              onClick={() => (window.location.href = `mailto:${email}`)}
            >
              {email}
            </button>
          </div>
        </div>
      </MainLayout>
    </div>
  );
}
