import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CommentType, COMMENT_TYPE } from './types';

interface StateType {
  loadingComments: boolean;
  errorComments: string;
  comments: CommentType[];
}

export const initialState: StateType = {
  loadingComments: false,
  errorComments: '',
  comments: [],
};

export const commentSlice = createSlice({
  name: 'comment',
  initialState,
  reducers: {
    getCommentsSuccess: (state, action: PayloadAction<CommentType[]>) => {
      state.comments = [...action.payload];
      state.loadingComments = false;
      state.errorComments = '';
    },
    commentsRequest: (state) => {
      state.loadingComments = true;
      state.errorComments = '';
    },
    commentsError: (state, action: PayloadAction<string>) => {
      state.errorComments = action.payload;
      state.loadingComments = false;
    },
    createCommentSuccess: (state, action: PayloadAction<CommentType>) => {
      if (action.payload.type === COMMENT_TYPE.HIGHLIGHT) state.comments.push(action.payload);
      state.loadingComments = false;
      state.errorComments = '';
    },
    deleteCommentSuccess: (state, action: PayloadAction<{id: string}>) => {
      state.comments = state.comments.filter((comment) => comment.id !== action.payload.id);
      state.loadingComments = false;
      state.errorComments = '';
    },
  },
});

export const { getCommentsSuccess, commentsError, commentsRequest, createCommentSuccess , deleteCommentSuccess} =
  commentSlice.actions;

export default commentSlice.reducer;
