import React, { useEffect } from 'react';
import axios from 'axios';
import BeatLoader from 'react-spinners/BeatLoader';

import styles from './styles.module.css';

export default function Linkedin() {
  useEffect(() => {
    const responseCode = new URL(window.location.href).searchParams.get('code');
    if (responseCode) {
      axios
        .post(`${process.env.REACT_APP_BOOK_API}linkedin`, {
          code: responseCode,
          content: localStorage.getItem('highlightContent'),
        })
        .then((response) => {
          window.close();
          return response;
        })
        .catch((err) => {
          // eslint-disable-next-line 
          console.log('Error:', err);
        });
    }
  }, []);

  return (
    <div className={styles.screen}>
      <span className={styles.title}>Posting on LinkedIn</span>
      <BeatLoader loading />
    </div>
  );
}
