import { Dispatch } from '@reduxjs/toolkit';

import { apiBook } from '../api';
import { booksError, booksRequest, getBooksSuccess } from './bookSlice';

export const getBooks = () => (dispatch: Dispatch) => {
  dispatch(booksRequest());
  apiBook
    .get('')
    .then(({ data }) => {
      return dispatch(getBooksSuccess(data));
    })
    .catch((error) => {
      dispatch(booksError(error.message));
    });
};
