import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './styles.module.css';

export default function Menu({ isOpen }: { isOpen: boolean }) {
  return (
    <div className={isOpen ? styles.openContainer : styles.container}>
      <NavLink
        key="about"
        exact
        className={styles.link}
        to="/about"
        activeClassName={styles.activeNavlink}
      >
        About
      </NavLink>
      <NavLink
        key="privacy"
        exact
        className={styles.link}
        to="/privacy"
        activeClassName={styles.activeNavlink}
      >
        Privacy Policy
      </NavLink>
      <NavLink
        key="contact"
        exact
        className={styles.link}
        to="/contact"
        activeClassName={styles.activeNavlink}
      >
        Are you an Author or Investor?
      </NavLink>
    </div>
  );
}
