import { createSlice } from '@reduxjs/toolkit';

interface StateType {
  isChapterOpen: boolean;
}

export const initialState: StateType = {
  isChapterOpen: false,
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setIsChapterOpen: (state) => {
      state.isChapterOpen = !state.isChapterOpen;
    },
  },
});

export const { setIsChapterOpen } = globalSlice.actions;

export default globalSlice.reducer;
