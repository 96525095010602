import React from 'react';

import styles from './styles.module.css';

interface ButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  children: React.ReactNode;
  rest?: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;
  ariaLabel?: string;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  fullWidth?: boolean;
  isDisabled?: boolean;
}

export default function Button({
  onClick = () => {},
  className,
  ariaLabel,
  isDisabled,
  disabled = false,
  children,
  type = 'button',
  fullWidth = false,
  ...rest
}: ButtonProps) {
  return (
    <button
      className={`${isDisabled ? styles.buttonDisabled : styles.button} ${className}`}
      style={{ width: fullWidth ? '100%' : 'unset' }}
      aria-label={ariaLabel}
      onClick={onClick}
      disabled={isDisabled || disabled}
      type={type}
      {...rest}
    >
      {children}
    </button>
  );
}
