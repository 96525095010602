import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';

import { MainLayout } from 'Layouts';
import styles from './styles.module.css';

export default function About() {
  return (
    <div className={styles.screen}>
      <MainLayout mainClassname={styles.container}>
        <div className={styles.title}>A Note from the Author</div>
        <div className={styles.text}>
          <p>
            I finished the first draft of this book in November of 2019. I had a wonderful
            publishing agent and dear friends who helped me craft, tweak and massage the book into a
            place I was very proud of. My agent was getting good feedback from publishers, which
            felt amazing as a first time author.
          </p>
          <p>
            Then - Covid. I have spent my career building products, not a following, and so with my
            limited social media footprint and lack of previous work, I became very challenging to
            publish. This book speaks to Product and Product led founders, which is a niche market.
            I didn’t want huge marketing behind it, I didn’t think I needed it, but alas, it was not
            publishable.
          </p>
          <p>
            For the better part of a year I continued working with my agent, putting together
            pitches and plans that could mitigate some of the reasons publishers were shying away
            from the book. There were moments of hope, usually followed by long periods of silence.
            It was disheartening.
          </p>
          <p>
            Part of the original idea was that WITH the book should come a digital product. It is a
            book about building them, after all. The concept was to create a website and a podcast,
            building a community of founders and product people who wanted to learn and to push the
            practice forward. I have always believed that the issue with books like this one is that
            after they are published, they need to be updated within a month or two based on the
            dialogue had around the book.
          </p>
          <p>
            So with no publishers publishing and that in mind, this concept was born. Books like
            this one are ABOUT the dialog when done well. So instead of taking to Twitter or some
            random Barns and Noble on a Wednesday, I wanted to take the conversation to the web. In
            doing so, I wanted it to be truly collaborative and engaging, letting not only readers
            discuss their thoughts with each other, but also with the author.
          </p>
        </div>
        <div className={styles.sectionTitle}>Special Thanks To...</div>
        <div className={styles.text}>
          <p>
            Tom Goodwin, who without his words of encouragement and belief in me this never comes
            together
          </p>
          <p>Danny Nathan, who encouraged not only the text but to not give up on the platform</p>
          <p>Maureen Garvy, my first (and favorite) editor </p>
          <p>Jason Osburn, who’s ability to make me think about things is unparalleled</p>
          <p>
            Ryan Bricklemeyer, who told me he wanted other people to read my book, which is the
            nicest thing anyone can say about your book
          </p>
          <p>Jeremy Toeman, who confirmed that I am, in fact, not bad at this product thing </p>
          <p>
            Jeffery Arnowitz, who might have been the only person to read and give notes on the
            entire book - notes I am so grateful for
          </p>
          <p>Kayla, Lennon, Charlie, it’s all for you, always.</p>
          <p>Perette, Paeden, who were cheerleaders even when everyone else had stopped cheering</p>
          <p>Mom, Dad, LJ, Aurora, so much of who I am is because of you</p>
        </div>
        <div className={styles.sectionTitle}>Check our privacy policy</div>
        <div className={styles.text}>
          <Fragment key="privacy - privacy">
            <NavLink exact className={styles.link} to="/privacy">
              Privacy Policy
            </NavLink>
          </Fragment>
        </div>
      </MainLayout>
    </div>
  );
}
