import _ from 'lodash';
import moment from 'moment';

import { CommentType } from 'Slices/comment/types';

export const getInitials = (fullName: string | null) => {
  const nameArray = fullName?.split(' ');
  if (nameArray?.[1]) return `${nameArray?.[0]?.charAt(0)}${nameArray?.[1]?.charAt(0)}`;
  return nameArray?.[0]?.charAt(0);
};

export const camelizeObject = (data) => {
  return Object.entries(data).reduce((result, [key, value]) => {
    result[_.camelCase(key)] = value;
    return result;
  }, {});
};

export const decamelize = (data) => {
  return Object.entries(data).reduce((result, [key, value]) => {
    result[_.snakeCase(key)] = value;
    return result;
  }, {});
};

export const camelize = (data) => {
  return data.map((item) => camelizeObject(item));
};

export const calculateDays = (date: string) => {
  return moment(date).fromNow();
};

export const getAmountComments = (comments: CommentType[]) => {
  const commentsAmount = comments.length;
  const repliesAmount = comments.reduce((result, item) => {
    result += item.replies?.commentCount;
    return result;
  }, 0);
  return commentsAmount + repliesAmount;
};

export const cleanParagraph = (text: string) =>  new DOMParser().parseFromString(text, 'text/html').documentElement.textContent?.replace('&#8216;', "'");
