import React, { Fragment, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { appLogoLong, closeBlackIcon, menuIconMobile } from 'Assets/Images';
import { useIsMobile } from 'Hooks';
import { Menu } from 'Components';
import styles from './styles.module.css';

export default function MainLayout({
  children,
  mainClassname,
}: {
  children: React.ReactNode;
  mainClassname?: string;
}) {
  const isMobile = useIsMobile();
  const [isMenuOpen, setMenuOpen] = useState(false);

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <NavLink exact className={isMobile ? styles.titleMobile : styles.title} to="/">
          <img src={appLogoLong} className={isMobile ? styles.appLogoMobileHeader : styles.appLogoHeader} alt="Build for Better" />
        </NavLink>
        <div>
          {!isMobile ? (
            <>
              <Fragment key="about - about">
                <NavLink exact className={styles.link} to="/about">
                  About
                </NavLink>
              </Fragment>
              <Fragment key="privacy - privacy">
                <NavLink exact className={styles.link} to="/privacy">
                  Privacy Policy
                </NavLink>
              </Fragment>
              <Fragment key="contact - contact">
                <NavLink exact className={styles.link} to="/contact">
                  Are you an Author or Investor?
                </NavLink>
              </Fragment>
            </>
          ) : (
            <>
              <button className={styles.invisibleButton} onClick={() => setMenuOpen(!isMenuOpen)}>
                <img src={isMenuOpen ? closeBlackIcon : menuIconMobile} alt="Icon" />
              </button>
              <Menu isOpen={isMenuOpen} />
            </>
          )}
        </div>
      </header>
      <main className={`${styles.main} ${mainClassname}`}>{children}</main>
    </div>
  );
}
