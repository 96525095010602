import axios from 'axios';

const bookApi = process.env.REACT_APP_BOOK_API || 'http://localhost:4000';
const commentApi = process.env.REACT_APP_COMMENT_API || 'http://localhost:3000';
const commentAppId = process.env.REACT_APP_COMMENT_APP_ID || 'd833240b-de6d-49e0-8b02-8656d2af4835';

export const apiComments = axios.create({
  baseURL: `${commentApi}/api/open/comments?appId=${commentAppId}`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Timezone-Offset': `${new Date().getTimezoneOffset()}`,
  },
});

export const apiBook = axios.create({
  baseURL: bookApi,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Timezone-Offset': `${new Date().getTimezoneOffset()}`,
  },
});
