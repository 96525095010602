import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import { commentSlice, bookSlice, globalSlice } from 'Slices';

export const store = configureStore({
  reducer: {
    comment: commentSlice,
    book: bookSlice,
    global: globalSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
