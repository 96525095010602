/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Resizable } from 're-resizable';

import { MainLayout, CommentLayout } from 'Layouts';
import { Book, ChapterList, ChaptersModal, CommentsList, CommentsModal } from 'Components';
import { useIsMobile } from 'Hooks';
import { getComments } from 'Slices/comment/apiCall';
import { RootState } from 'store';
import { getAmountComments } from 'Utils';
import { Button } from 'Components/Common';
import { chatWhiteIcon, menuWhiteIcon } from 'Assets/Images';
import { getBooks } from 'Slices/book/apiCall';
import { CommentType, COMMENT_TYPE, SelectionType } from 'Slices/comment/types';
import styles from './styles.module.css';

export default function Home() {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const { books, glossary } = useSelector((state: RootState) => state.book);
  const { isChapterOpen } = useSelector((state: RootState) => state.global);
  const { comments: commentsData } = useSelector((state: RootState) => state.comment);
  const [selection, setSelection] = useState<SelectionType | null>(null);
  const [isOpenCommentsModal, setIsOpenCommentsModal] = useState(false);
  const [isOpenChaptersModal, setIsOpenChaptersModal] = useState(false);
  const [filteredComments, setFilteredComments] = useState<CommentType[]>([]);

  const amount = getAmountComments(filteredComments);

  useEffect(() => {
    dispatch(getComments());
    dispatch(getBooks());
  }, []);

  useEffect(() => {
    setFilteredComments(commentsData.filter((comment) => comment.type === COMMENT_TYPE.COMMENT));
  }, [commentsData]);

  useEffect(() => {
    if (isMobile && selection) setIsOpenCommentsModal(true);
  }, [selection]);

  const handleCommentClick = (id: string) => {
    setFilteredComments(
      commentsData.filter(
        ({ referenceId, type }) => referenceId === id && type === COMMENT_TYPE.COMMENT,
      ),
    );
    if (isMobile) setIsOpenCommentsModal(true);
  };

  const groupCommentsWithHighlights = () =>
    commentsData.map(
      ({ referenceContent, referenceId, referencePos, referenceLength, type, id }) => ({
        referenceId,
        highlightContent: referenceContent,
        type,
        id,
        start: referencePos,
        end:
          referencePos != null && referenceLength != null ? referencePos + referenceLength : null,
      }),
    );

  return (
    <div className={styles.screen}>
      <div className={styles.bookContainer}>
        <MainLayout mainClassname={styles.mainLayoutMain}>
          <Book
            book={books[0]}
            setSelection={setSelection}
            comments={groupCommentsWithHighlights()}
            handleClick={handleCommentClick}
          />
          {isMobile && (
            <>
              <Button className={styles.button}>
                <button
                  className={styles.invisibleButton}
                  onClick={() => {
                    setIsOpenCommentsModal(true);
                  }}
                >
                  <img className={styles.icon} src={chatWhiteIcon} alt="chat icon" />
                  {amount > 0 && <span>{amount}</span>}
                </button>
                <button
                  className={styles.invisibleButton}
                  onClick={() => setIsOpenChaptersModal(true)}
                >
                  <img src={menuWhiteIcon} className={styles.menuIcon} alt="menu icon" />
                </button>
              </Button>
              <CommentsModal
                open={isOpenCommentsModal}
                comments={filteredComments}
                amount={amount}
                selection={selection}
                handleClose={() => setIsOpenCommentsModal(false)}
              />
              <ChaptersModal
                open={isOpenChaptersModal}
                glossary={glossary}
                handleClose={() => setIsOpenChaptersModal(false)}
              />
            </>
          )}
        </MainLayout>
      </div>
      {!isMobile && (
        <Resizable
          minWidth={320}
          enable={{ left: true }}
          defaultSize={{ width: 320, height: 'auto' }}
          handleStyles={{
            left: {
              marginTop: -35,
              marginLeft: -3,
              top: '50%',
              left: 0,
              cursor: 'ew-resize',
              border: '3px solid #999',
              borderTop: 'none',
              borderRight: 'none',
              borderBottom: 'none',
              borderWidth: 5,
              borderRadius: 20,
              borderColor: '#000',
              height: 35,
              boxSizing: 'border-box',
              zIndex: 1,
              width: 'unset',
            },
          }}
        >
          <div className={styles.commentsContainer}>
            <CommentLayout amount={amount} selection={selection} setSelection={setSelection}>
              {isChapterOpen ? (
                <ChapterList glossary={glossary} />
              ) : (
                <CommentsList comments={filteredComments} />
              )}
            </CommentLayout>
          </div>
        </Resizable>
      )}
    </div>
  );
}
