import React from 'react';

import { CommentType } from 'Slices/comment/types';
import Comment from 'Components/Comment';
import styles from './styles.module.css';

export interface CommentsListProps {
  comments: CommentType[];
  isReply?: boolean;
  isInputFocused?: boolean;
  handleAddReplyMobile?: (parentId: string) => void;
}
export default function CommentsList({
  comments,
  isReply,
  handleAddReplyMobile,
  isInputFocused,
}: CommentsListProps) {
  return (
    <div className={isInputFocused ? styles.shortContainer : styles.container}>
      {comments.map((comment, index) => (
        <Comment
          key={comment.id}
          comment={comment}
          isLast={index === comments.length - 1}
          isReply={isReply}
          handleAddReplyMobile={handleAddReplyMobile}
        />
      ))}
    </div>
  );
}
