import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Resizable } from 're-resizable';

import { CommentForm } from 'Components';
import { RootState } from 'store';
import { setIsChapterOpen } from 'Slices/global/globalSlice';
import { SelectionType } from 'Slices/comment/types';
import { apolloLogo, chatIcon, menuIcon } from '../../Assets/Images';
import styles from './styles.module.css';

export default function CommentLayout({
  children,
  amount,
  selection,
  setSelection,
}: {
  children: React.ReactNode;
  amount: number;
  selection: SelectionType | null;
  setSelection: React.Dispatch<React.SetStateAction<SelectionType | null>>;
}) {
  const dispatch = useDispatch();
  const [height, setHeight] = useState(250);
  const { isChapterOpen } = useSelector((state: RootState) => state.global);

  const getMainClassName = () => {
    if (isChapterOpen) return styles.main;
    if (selection?.highlightContent) return styles.highlightedCommentsMain;
    return styles.commentsMain;
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        {isChapterOpen ? <span>Table of Contents</span> : <span>Recent Comments ({amount})</span>}
        <button className={styles.invisibleButton} onClick={() => dispatch(setIsChapterOpen())}>
          <img src={isChapterOpen ? chatIcon : menuIcon} alt="Icon" />
        </button>
      </header>
      <main
        style={ isChapterOpen ? undefined : { maxHeight: `calc(100vh - 110px - ${height}px)` }}
        className={getMainClassName()}
      >
        {children}
      </main>
      <footer>
        {!isChapterOpen && (
          <Resizable
            minHeight={250}
            enable={{ top: true }}
            onResize={(event, direction, elementRef ) => setHeight(elementRef.clientHeight)}
            handleStyles={{
              top: {
                marginTop: -3,
                marginLeft: -15,
                top: 0,
                left: '50%',
                cursor: 'ns-resize',
                border: '3px solid #999',
                borderLeft: 'none',
                borderRight: 'none',
                borderBottom: 'none',
                borderWidth: 5,
                borderColor: '#000',
                width: 35,
                height: 'unset',
                boxSizing: 'border-box',
                zIndex: 1,
                borderRadius: 20,
              },
            }}
          >
            <CommentForm selection={selection} setSelection={setSelection} />
          </Resizable>
        )}
        <div className={styles.footer}>
          Created with
          <span role="img" aria-label="rocket" className={styles.rocket}>
            🚀
          </span>
          fuel by
          <a href="https://www.apollo21.io" target="_blank" rel="noopener noreferrer">
            <img src={apolloLogo} className={styles.logo} alt="Apollo logo" />
          </a>
          | &#169; 2022
        </div>
      </footer>
    </div>
  );
}
