import { Dispatch } from '@reduxjs/toolkit';

import { camelize, camelizeObject, decamelize } from '../../Utils/index';
import { apiComments } from '../api';
import {
  commentsError,
  commentsRequest,
  createCommentSuccess,
  deleteCommentSuccess,
  getCommentsSuccess,
} from './commentSlice';
import { CommentBodyType, CommentType } from './types';

const commentAppId = process.env.REACT_APP_COMMENT_APP_ID || 'd833240b-de6d-49e0-8b02-8656d2af4835';

export const getComments = () => (dispatch: Dispatch) => {
  dispatch(commentsRequest());
  apiComments
    .get('')
    .then(({ data: { data } }) => dispatch(getCommentsSuccess(camelize(data.data))))
    .catch((error) => {
      dispatch(commentsError(error.message));
    });
};

export const createComment =
  (comment: CommentBodyType, parentId?: string) => (dispatch: Dispatch) => {
    dispatch(commentsRequest());
    const commentBody = {
      appId: commentAppId,
      parentId: parentId || null,
      pageId: '',
      ...decamelize(comment),
    };
    apiComments
      .post('', commentBody)
      .then(({ data: { data } }) =>
        dispatch(createCommentSuccess(camelizeObject(data) as CommentType)),
      )
      .catch((error) => {
        dispatch(commentsError(error.message));
      });
  };

export const deleteComment =
  ({ id }: { id: string }) =>
  (dispatch: Dispatch) => {
    dispatch(commentsRequest());
    apiComments
      .delete('', { params: { id } })
      .then(({ data: { data } }) =>
        dispatch(deleteCommentSuccess(data)),
      )
      .catch((error) => {
        dispatch(commentsError(error.message));
      });
  };
