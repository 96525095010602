/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import { MainLayout } from 'Layouts';
import { REACT_APP_CONTACT_EMAIL } from 'Utils/constants';
import styles from './styles.module.css';

export default function Contact() {
  const email = REACT_APP_CONTACT_EMAIL;
  return (
    <div className={styles.screen}>
      <MainLayout mainClassname={styles.container}>
        <div className={styles.title}>Are you an Author or Investor?</div>
        <div className={styles.text}>
          <p>
            This platform might be more interesting than anything you'll read in the book. In
            reality, the world moves faster than publishing companies can publish. And the brilliant
            minds behind the books we all love are gate-kept by their agents. And, perhaps most
            importantly, those authors are making pennies on the dollar for their work.
          </p>
          <p>
          The intention of this platform is to change that. It is centered around these concepts:
          </p>
          <p>
          <ul>
            <li>Authors should decide how and when they access their audience</li>
            <li>The publishing world is set up far too favorably in the publishers internest, not the authors</li>
            <li>Building communities around conversation about a topic someone is passionate about is amazing</li>
            <li>Everything is made better through discussion, debate and inclusivity</li>
          </ul>
          </p>
          <p>
          That’s it. That’s what I am trying to do here
          </p>
          <p>
          If you would like to discuss the platform, the potential business model, or anything else, contact me at
          <button
            className={styles.link}
            // eslint-disable-next-line no-return-assign
            onClick={() => (window.location.href = `mailto:${email}`)}
          >
            {email}
          </button>
          </p>
        </div>
      </MainLayout>
    </div>
  );
}
