import React from 'react';

import styles from './styles.module.css';

export interface TextAreaProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  className?: string;
  rest?: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  >;
  disabled?: boolean;
  value?: string;
  innerRef?:
    | string
    | ((instance: HTMLTextAreaElement | null) => void)
    | React.RefObject<HTMLTextAreaElement>;
  isTextField?: boolean;
}

export default function TextArea({
  className,
  onChange,
  value,
  disabled,
  innerRef,
  isTextField,
  ...rest
}: TextAreaProps) {
  return (
    <textarea
      className={styles.textfield}
      value={value}
      onChange={onChange}
      disabled={disabled}
      type="text"
      ref={innerRef}
      {...rest}
    />
  );
}
