export interface ReplyType {
  data: CommentType[];
  commentCount: number;
}

export interface CommentType {
  id: string;
  createdAt: string;
  content: string;
  byChapter: string;
  byNickname: string;
  byEmail: string;
  referenceContent: string | null;
  referenceId: string | null;
  referencePos: number | null;
  referenceLength: number | null;
  type: "HIGHLIGHT" | "COMMENT";
  page: {
    id: string;
    slug: string;
    url: string;
    title: string;
    createdAt: string;
    updatedAt: string;
    projectId: string;
  };
  replies: ReplyType;
}
export interface CommentBodyType {
  chapter: string;
  content: string;
  email: string;
  nickname: string;
  parentId?: string;
  referenceContent: string | null;
  referenceId: string | null;
  referencePos: number | null;
  referenceLength: number | null;
  type: string;
}

export interface SelectionType {
  start: number | null;
  end: number | null;
  paragraphId: string;
  highlightContent: string | null;
  chapterId: string;
}

export const COMMENT_TYPE = {
  HIGHLIGHT: 'HIGHLIGHT',
  COMMENT: 'COMMENT',
}
